import { baseUrlTranf } from "../../constants/defaultValues";

export const LISTAR_DATOS_TRANSF = "LISTAR_DATOS_TRANSF";

export const listar_datos_transf = () => {
  return async (dispatch) => {
    fetch(`${baseUrlTranf}listar`).then(async (response) => {
      let list = await response.json();
      dispatch({
        type: LISTAR_DATOS_TRANSF,
        payload: list.length > 0 ? list[0] : list,
      });
    });
  };
};
