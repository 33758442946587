import { baseUrlDatos } from "../../constants/defaultValues";

export const LISTAR_DATOS = "LISTAR_DATOS";

export const listar_datos = () => {
  return async (dispatch) => {
    fetch(`${baseUrlDatos}listar`).then(async (response) => {
      let list = await response.json();
      dispatch({
        type: LISTAR_DATOS,
        payload: list.length > 0 ? list[0] : list,
      });
    });
  };
};
