import { LISTAR_BANNER } from "../actions/bannnerAction";

const initState = [];

const bannerReducer = (state = initState, action) => {
  const banner = action.payload;
  if (action.type === LISTAR_BANNER) {
    return banner;
  }
  return state;
};

export default bannerReducer;
