import { baseUrlWish } from "../../constants/defaultValues";

export const ADD_TO_WISHLIST = "ADD_TO_WISHLIST";
export const DELETE_FROM_WISHLIST = "DELETE_FROM_WISHLIST";
export const DELETE_ALL_FROM_WISHLIST = "DELETE_ALL_FROM_WISHLIST";
export const LISTAR_WISH = "LISTAR_WISH";

export const listarWish = () => {
  return async (dispatch) => {
    ///falta reducer
    const usuario = JSON.parse(sessionStorage.getItem("usuario"));
    let usuid = usuario !== null ? usuario.id : 0;
    fetch(`${baseUrlWish}listar?usuid=${usuid}`).then(async (response) => {
      let list = await response.json();
      dispatch({
        type: LISTAR_WISH,
        payload: list,
      });
    });
  };
};

// add to wishlist
export const addToWishlist = (item, addToast) => {
  return async (dispatch) => {
    const usuario = JSON.parse(sessionStorage.getItem("usuario"));
    if (usuario !== null) {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(item),
      };
      let response = await fetch(
        `${baseUrlWish}save?usuid=${usuario.id}`,
        options
      );
      if (response.status === 200) {
        if (addToast) {
          addToast("Agregado a lista de deseos", {
            appearance: "success",
            autoDismiss: true,
          });
        }
        dispatch({ type: ADD_TO_WISHLIST, payload: item });
      } else {
        if (addToast) {
          addToast("No fue posible agregar a lista de deseos", {
            appearance: "error",
            autoDismiss: true,
          });
        }
      }
    } else {
      if (addToast) {
        addToast("Agregado a lista de deseos", {
          appearance: "success",
          autoDismiss: true,
        });
      }
      dispatch({ type: ADD_TO_WISHLIST, payload: item });
    }
  };
};

// delete from wishlist
export const deleteFromWishlist = (item, addToast) => {
  return async (dispatch) => {
    const usuario = JSON.parse(sessionStorage.getItem("usuario"));
    if (usuario !== null) {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(item),
      };
      let response = await fetch(
        `${baseUrlWish}dlt?usuid=${usuario.id}`,
        options
      );
      if (response.status === 200) {
        if (addToast) {
          addToast("Removido de la lista de deseos", {
            appearance: "error",
            autoDismiss: true,
          });
        }
        dispatch({ type: DELETE_FROM_WISHLIST, payload: item });
      } else {
        if (addToast) {
          addToast("No fue posible remover de lista de deseos", {
            appearance: "error",
            autoDismiss: true,
          });
        }
      }
    } else {
      if (addToast) {
        addToast("Removido de la lista de deseos", {
          appearance: "error",
          autoDismiss: true,
        });
      }
      dispatch({ type: DELETE_FROM_WISHLIST, payload: item });
    }
  };
};

//delete all from wishlist
export const deleteAllFromWishlist = (addToast) => {
  return async (dispatch) => {
    const usuario = JSON.parse(sessionStorage.getItem("usuario"));
    if (usuario !== null) {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      };
      let response = await fetch(
        `${baseUrlWish}dltall?usuid=${usuario.id}`,
        options
      );
      if (response.status === 200) {
        if (addToast) {
          addToast("Todos los artículos removidos", {
            appearance: "error",
            autoDismiss: true,
          });
        }
        dispatch({ type: DELETE_ALL_FROM_WISHLIST });
      } else {
        if (addToast) {
          addToast("No fue posible remover la lista de deseos", {
            appearance: "error",
            autoDismiss: true,
          });
        }
      }
    } else {
      if (addToast) {
        addToast("Todos los artículos removidos", {
          appearance: "error",
          autoDismiss: true,
        });
      }
      dispatch({ type: DELETE_ALL_FROM_WISHLIST });
    }
    if (addToast) {
      addToast("Todos los artículos removidos", {
        appearance: "error",
        autoDismiss: true,
      });
    }
    dispatch({ type: DELETE_ALL_FROM_WISHLIST });
  };
};
