import { LISTAR_CATALOGOS } from "../actions/catalogosAction";

const initState = [];

const catalogoReducer = (state = initState, action) => {
  const catalogos = action.payload;
  if (action.type === LISTAR_CATALOGOS) {
    return catalogos;
  }
  return state;
};

export default catalogoReducer;
