import { baseUrlPedido } from "../../constants/defaultValues";

export const LISTAR_PEDIDOS = "LISTAR_PEDIDOS";
export const listarPedidos = () => {
  return async (dispatch) => {
    const usuario = JSON.parse(sessionStorage.getItem("usuario"));
    if (usuario !== null) {
      fetch(`${baseUrlPedido}listarw?usuMail=${usuario.email}`).then(
        async (response) => {
          let list = await response.json();
          list = list.sort((a, b) => {
            return b.nro - a.nro;
          });
          dispatch({
            type: LISTAR_PEDIDOS,
            payload: list,
          });
        }
      );
    }
  };
};
