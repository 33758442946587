import { LISTAR_DATOS } from "../actions/dataActions";

const initState = [];

const dataReducer = (state = initState, action) => {
  const configuracion = action.payload;
  if (action.type === LISTAR_DATOS) {
    return configuracion;
  }
  return state;
};

export default dataReducer;
