// export const baseUrl =
//   "https://back13.casadelostaladros.com.py/apit/v1/producto/";
export const baseUrlCar =
  "https://back13.casadelostaladros.com.py/apit/v1/car/";
export const baseUrlWish =
  "https://back13.casadelostaladros.com.py/apit/v1/wish/";
export const baseUrlUsu =
  "https://back13.casadelostaladros.com.py/apit/v1/usuario/";
export const baseUrlPedido =
  "https://back13.casadelostaladros.com.py/apit/v1/pedido/";
export const baseUrlDatos =
  "https://back13.casadelostaladros.com.py/apit/v1/datos/";
export const baseUrlCatalogo =
  "https://back13.casadelostaladros.com.py/apit/v1/catalogos/";
export const baseUrlBanner =
  "https://back13.casadelostaladros.com.py/apit/v1/banner/";
// export const baseUrlTranf =
//   "https://back13.casadelostaladros.com.py/apit/v1/transf/";
export const baseUrl = "http://localhost:8090/apit/v1/producto/";
// export const baseUrlCar = "http://localhost:8090/apit/v1/car/";
// export const baseUrlWish = "http://localhost:8090/apit/v1/wish/";
// export const baseUrlUsu = "http://localhost:8090/apit/v1/usuario/";
// export const baseUrlPedido = "http://localhost:8090/apit/v1/pedido/";
// export const baseUrlDatos = "http://localhost:8090/apit/v1/datos/";
// export const baseUrlCatalogo = "http://localhost:8090/apit/v1/catalogos/";
// export const baseUrlBanner = "http://localhost:8090/apit/v1/banner/";
export const baseUrlTranf = "http://localhost:8090/apit/v1/transf/";
