import { baseUrl } from "../../constants/defaultValues";
import axios from "axios";

export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const LISTAR = "LISTAR";
export const GRABAR = "GRABAR";
export const RECUPERAR = "RECUPERAR";
export const MODIFICAR = "MODIFICAR";
export const ELIMINAR = "ELIMINAR";

// const fetchProductsSuccess = (products) => ({
//   type: FETCH_PRODUCTS_SUCCESS,
//   payload: products,
// });

// export const fetchProducts = (products) => {
//   return (dispatch) => {
//     dispatch(fetchProductsSuccess(products));
//   };
// };

export const fetchProducts = () => {
  return async (dispatch) => {
    return axios
      .get(`${baseUrl}getList`)
      .then((response) => {
        dispatch({
          type: LISTAR,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};
