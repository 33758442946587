import { baseUrlBanner } from "../../constants/defaultValues";

export const LISTAR_BANNER = "LISTAR_BANNER";

export const listar_banners = () => {
  return async (dispatch) => {
    fetch(`${baseUrlBanner}listar`).then(async (response) => {
      let list = await response.json();
      dispatch({
        type: LISTAR_BANNER,
        payload: list,
      });
    });
  };
};
