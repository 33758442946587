import { baseUrlCatalogo } from "../../constants/defaultValues";

export const LISTAR_CATALOGOS = "LISTAR_CATALOGOS";

export const listar_catalogos = () => {
  return async (dispatch) => {
    fetch(`${baseUrlCatalogo}listar`).then(async (response) => {
      let list = await response.json();
      dispatch({
        type: LISTAR_CATALOGOS,
        payload: list,
      });
    });
  };
};
