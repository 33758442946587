import {
  FETCH_PRODUCTS_SUCCESS,
  LISTAR,
  GRABAR,
  RECUPERAR,
  MODIFICAR,
  ELIMINAR,
} from "../actions/productActions";

const initState = {
  products: [],
};

const productReducer = (state = initState, action) => {
  switch (action.type) {
    case LISTAR:
      return {
        ...state,
        products: action.payload,
      };
    case GRABAR:
      return {
        ...state,
        List: [...state.List, action.payload],
      };
    case RECUPERAR:
      return {
        ...state,
        modelActual: action.payload,
      };
    case MODIFICAR:
      return {
        ...state,
        List: state.List.map((model) =>
          model.id === action.payload.id ? action.payload : model
        ),
      };
    case ELIMINAR:
      return {
        ...state,
        List: state.List.filter((model) => model.id !== action.payload),
      };
    default:
      return state;
  }
  // if (action.type === FETCH_PRODUCTS_SUCCESS) {
  //   return {
  //     ...state,
  //     products: action.payload
  //   };
  // }

  // return state;
};

export default productReducer;
