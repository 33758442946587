import { changeLanguage } from "redux-multilanguage";
import { baseUrlCar } from "../../constants/defaultValues";

export const ADD_TO_CART = "ADD_TO_CART";
export const DECREASE_QUANTITY = "DECREASE_QUANTITY";
export const DELETE_FROM_CART = "DELETE_FROM_CART";
export const DELETE_ALL_FROM_CART = "DELETE_ALL_FROM_CART";
export const LISTAR = "LISTAR_CARRITO";

export const listar = () => {
  return async (dispatch) => {
    const usuario = JSON.parse(sessionStorage.getItem("usuario"));
    if (usuario !== null) {
      let usuid = usuario.id;
      fetch(`${baseUrlCar}listar?usuid=${usuid}`).then(async (response) => {
        let list = await response.json();
        if (list.length === 0) {
          dispatch({ type: DELETE_ALL_FROM_CART });
        } else {
          dispatch({
            type: LISTAR,
            payload: list,
          });
        }
      });
    }
  };
};

//add to cart
export const addToCart = (
  item,
  addToast,
  quantityCount,
  selectedProductColor,
  selectedProductSize
) => {
  return async (dispatch) => {
    const usuario = JSON.parse(sessionStorage.getItem("usuario"));
    let usuid = usuario !== null ? usuario.id : 0;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...item,
        quantity: quantityCount,
        selectedProductColor: selectedProductColor
          ? selectedProductColor
          : item.selectedProductColor
          ? item.selectedProductColor
          : null,
        selectedProductSize: selectedProductSize
          ? selectedProductSize
          : item.selectedProductSize
          ? item.selectedProductSize
          : null,
      }),
    };
    let response = await fetch(`${baseUrlCar}add?usuid=${usuid}`, options);
    if (response.status) {
      if (addToast) {
        addToast("Agregado al carrito", {
          appearance: "success",
          autoDismiss: true,
        });
        //grabado del carrito
      }
      let json = await response.json();
      let jsonStr = JSON.stringify(json);
      dispatch({
        type: ADD_TO_CART,
        payload: JSON.parse(jsonStr),
      });
    }
  };
};
//decrease from cart
export const decreaseQuantity = (item, addToast) => {
  return (dispatch) => {
    const usuario = JSON.parse(sessionStorage.getItem("usuario"));
    let usuid = usuario !== null ? usuario.id : 0;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(item),
    };
    fetch(`${baseUrlCar}remove?usuid=${usuid}`, options);
    if (addToast) {
      addToast("Artículo removido del carrito", {
        appearance: "warning",
        autoDismiss: true,
      });
      ///disminuido
    }
    dispatch({ type: DECREASE_QUANTITY, payload: item });
  };
};
//delete from cart
export const deleteFromCart = (item, addToast) => {
  return (dispatch) => {
    const usuario = JSON.parse(sessionStorage.getItem("usuario"));
    let usuid = usuario !== null ? usuario.id : 0;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(item),
    };
    fetch(`${baseUrlCar}removeProd?usuid=${usuid}`, options);
    if (addToast) {
      addToast("Removido del carrito", {
        appearance: "error",
        autoDismiss: true,
      });
      //borrado
    }
    dispatch({ type: DELETE_FROM_CART, payload: item });
  };
};
//delete all from cart
export const deleteAllFromCart = (addToast) => {
  return (dispatch) => {
    const usuario = JSON.parse(sessionStorage.getItem("usuario"));
    let usuid = usuario !== null ? usuario.id : 0;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: usuid, nombre: "", apellido: "" }),
    };
    fetch(`${baseUrlCar}removeAll`, options);
    if (addToast) {
      addToast("Todos los artículos removidos", {
        appearance: "error",
        autoDismiss: true,
      });
      ///borrar todo el
    }
    dispatch({ type: DELETE_ALL_FROM_CART });
  };
};

// get stock of cart item
export const cartItemStock = (item, color, size) => {
  if (item.stock) {
    return item.stock;
  } else {
    return item.variation
      .filter((single) => single.color === color)[0]
      .size.filter((single) => single.name === size)[0].stock;
  }
};
