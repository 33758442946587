import { SET_CURRENCY } from "../actions/currencyActions";

const initState = {
  currencySymbol: "Gs.",
  currencyName: "PYG",
  currencyRate: 1,
};
// currencySymbol: "€",
// currencyName: "EUR",

const currencyReducer = (state = initState, action) => {
  if (action.type === SET_CURRENCY) {
    const currencyName = action.payload.currencyName;

    if (currencyName === "USD") {
      return {
        ...state,
        currencySymbol: "$",
        currencyRate: action.payload.currencyRate,
        currencyName,
      };
    }
    // if (currencyName === "EUR") {
    //   return {
    //     ...state,
    //     currencySymbol: "€",
    //     currencyRate: action.payload.currencyRate,
    //     currencyName,
    //   };
    // }
    if (currencyName === "PYG") {
      return {
        ...state,
        currencySymbol: "Gs.",
        currencyRate: action.payload.currencyRate,
        currencyName,
      };
    }
    if (currencyName === "GBP") {
      return {
        ...state,
        currencySymbol: "£",
        currencyRate: action.payload.currencyRate,
        currencyName,
      };
    }
  }

  return state;
};

export default currencyReducer;
