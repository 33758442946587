import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { save, load } from "redux-localstorage-simple";
import { Provider } from "react-redux";
import { fetchProducts } from "./redux/actions/productActions";
import rootReducer from "./redux/reducers/rootReducer";
// import products from "./data/products.json";
import App from "./App";
import "./assets/scss/style.scss";
import * as serviceWorker from "./serviceWorker";

import { composeWithDevTools } from "redux-devtools-extension";
import { listar } from "./redux/actions/cartActions";
import { listarWish } from "./redux/actions/wishlistActions";
import { listar_datos } from "./redux/actions/dataActions";
import { listar_catalogos } from "./redux/actions/catalogosAction";
import { listarPedidos } from "./redux/actions/pedidosAction";
import { listar_banners } from "./redux/actions/bannnerAction";
import { listar_datos_transf } from "./redux/actions/transfActions";

const store = createStore(
  rootReducer,
  load(),
  composeWithDevTools(applyMiddleware(thunk, save()))
);

// fetch products from json file
// store.dispatch(fetchProducts(products));

// fetch products from my spring service purete
store.dispatch(fetchProducts());
store.dispatch(listar());
store.dispatch(listarWish());
store.dispatch(listar_datos());
store.dispatch(listar_catalogos());
store.dispatch(listarPedidos());
store.dispatch(listar_banners());
store.dispatch(listar_datos_transf());
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
