import { LISTAR_DATOS_TRANSF } from "../actions/transfActions";

const initState = [];

const transfReducer = (state = initState, action) => {
  const trasferencia = action.payload;
  if (action.type === LISTAR_DATOS_TRANSF) {
    return trasferencia;
  }
  return state;
};

export default transfReducer;
