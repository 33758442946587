import currencyReducer from "./currencyReducer";
import productReducer from "./productReducer";
import cartReducer from "./cartReducer";
import dataReducer from "./dataReducer";
import wishlistReducer from "./wishlistReducer";
import compareReducer from "./compareReducer";
import pedidosReducer from "./pedidosReducer";
import { combineReducers } from "redux";
import { createMultilanguageReducer } from "redux-multilanguage";
import catalogoReducer from "./catalogoReducer";
import bannerReducer from "./bannerReducer";
import transfReducer from "./transfReducer";

const rootReducer = combineReducers({
  multilanguage: createMultilanguageReducer({ currentLanguageCode: "es" }),
  currencyData: currencyReducer,
  productData: productReducer,
  cartData: cartReducer,
  wishlistData: wishlistReducer,
  compareData: compareReducer,
  dataConfig: dataReducer,
  catalogos: catalogoReducer,
  pedidos: pedidosReducer,
  banners: bannerReducer,
  transferencia: transfReducer,
});

export default rootReducer;
