import { LISTAR_PEDIDOS } from "../actions/pedidosAction";
const initState = [];

const pedidosReducer = (state = initState, action) => {
  const pedidos = action.payload;
  if (action.type === LISTAR_PEDIDOS) {
    return pedidos;
  }
  return state;
};
export default pedidosReducer;
